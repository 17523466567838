import React from "react"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import * as v from "../../components/variables"
import SEO from "../../components/seo"

import Header from "../../components/Header"
import CardList from "../../components/CardList"
import Card from "../../components/CardList/card"

const Container = styled.div`
  ${v.grid}
`

const Therapy = ({ data }) => {
  // const therapies = data.therapies.edges.map(therapy => therapy.node)

  const name = data.therapy.name.value
  const description = data.therapy.description?.value
  const modes = data.therapy.modes?.value

  return (
    <Layout>
      <SEO title={name} />
      <Container>
        <Header title={name} description={description} breadcrumb={[]}>
          <p></p>
        </Header>
        <CardList title={modes ? "Select a mode:" : "No modes yet"}>
          {modes &&
            modes.map(mode => (
              <Card
                image={mode.icon?.value.url}
                key={mode.name.slug}
                link={"/" + data.therapy.name.slug + "/" + mode.name.slug}
                label={mode.name.value}
              ></Card>
            ))}
        </CardList>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($therapyId: String) {
    therapy: cockpitTherapy(cockpitId: { eq: $therapyId }) {
      name {
        value
        slug
      }
      description {
        value
      }
      modes {
        value {
          cockpitId
          name {
            slug
            value
          }
          icon {
            value {
              url
            }
          }
        }
      }
    }
  }
`

export default Therapy
